<template>
    <span>
        {{ formattedValue }}
    </span>
</template>
<script>
export default {
    props: {
        item: {
            type: Object,
            required: true
        },
        name: {
            type: String,
            required: true
        }
    },
    computed: {
        formattedValue() {
            return this.item[this.name]?.formatted ?? '-';
        }
    }
};
</script>